import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config.json';

function LoginForm(props) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [expiration, setExpiration] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const serviceUrl_login = `${config.serviceUrl}/login`
      useEffect(() => {
        if (isChecked) {
          setExpiration(30);
        } else {
          setExpiration(1);
        }
      }, [isChecked]); // Depend on isChecked state
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);

            const requestBody = {
                "email": email,
                "password": password,
                "expiration": expiration
            }
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await axios.post(serviceUrl_login, requestBody, config);
            const data = response.data;
            localStorage.setItem('token', data.token);
            props.setAuthenticated(true);
            props.setUser(data.user);

        } catch (error) {
            try {
                setErrorMessage(error.response.data.message);
            } catch (error) {
                setErrorMessage("Please try again after sometime.");
            }
        }finally {
            setIsLoading(false); 
        }
    };

    return (
        <>
            <div className="row justify-content-center vh-100 bg-light">
                <div className="col-4 m-auto">
                    <div className="card p-3 shadow" >
                        <img
                            src="/elastiq_discover.png"
                            className="card-img-top mx-auto my-3"
                            alt="Elastiq Discover"
                            style={{ maxWidth: 250 }}
                        />
                        <div className="card-body">
                            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                            <form onSubmit={handleSubmit}>
                                <div className="form-floating mb-3">
                                    <input
                                        name="email"
                                        type="email"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        placeholder="name@example.com"
                                        aria-describedby="emailHelp"
                                        required={true}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <label htmlFor="exampleInputEmail1" >Email address</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        name="password"
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        id="exampleInputPassword1"
                                        required={true}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <label htmlFor="exampleInputPassword1">Password</label>

                                </div>
                                <div className="form-check text-start my-3">
                                    <input
                                        name="expiration"
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={isChecked}
                                        id="flexCheckDefault" 
                                        onChange={()=>{setIsChecked(!isChecked);}}
                                        />
                                        
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Remember me
                                    </label>
                                </div>
                                <button type="submit" className="btn btn-primary w-100" disabled={isLoading}>
                                    {isLoading && (
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    )}
                                    Login
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default LoginForm;

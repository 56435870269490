import React from 'react';
import { useState } from 'react';

function CopyButton(props) {
    const [isCopied, setIsCopied] = useState(false);

    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        navigator.clipboard.writeText(props.copyText)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <button
            className="p-2 chat-actions"
            onClick={handleCopyClick}
        >
            {isCopied ? <i className="bi bi-check-lg text-success"></i> : <i className="bi bi-clipboard"></i>}

        </button>
    );
}
export default CopyButton;

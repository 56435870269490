import React from 'react';
import { useState } from 'react';
import BarChart from './Bar';
import PieChart from './Pie';
import LineChart from './Line';
import axios from 'axios';
import config from '../config.json';


const Chart = (props) => {
    const data = props.data;
    const keys = Object.keys(data[0]);
    const [chartType, setChartType] = useState("bar");
    const [xAxis, setXAxis] = useState(keys[0]);
    const [yAxis, setYAxis] = useState(keys[1]);
    const [isLoading, setIsLoading] = useState(false);

    const serviceUrl_getGraphData = `${config.serviceUrl}/get-graph-data`
    const getGraphData = async () => {

        const requestBody = { "source": props.source, "xAxis": xAxis, "yAxis": yAxis, };
        try {
            setIsLoading(true);
            const config_home = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            };
            const response = await axios.post(serviceUrl_getGraphData, requestBody, config_home);
            props.setChartData(response.data.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="card m-2">
            <div className="input-group">
                <select
                    className="form-select"
                    value={chartType}
                    onChange={(e) => { ; setChartType(e.target.value); }}

                >
                    <option value="bar">Bar Chart</option>
                    <option value="line">Line Chart</option>
                    <option value="pie">Pie Chart</option>
                    <option value="doughnut">Doughnut Chart</option>
                </select>
                <select
                    className="form-select"
                    value={xAxis}
                    onChange={(e) => { ; setXAxis(e.target.value); }}
                >
                    {keys.map((k) => {
                        return (
                            <option key={k} value={k}>{k}</option>
                        )
                    })}
                </select>
                <select
                    className="form-select"
                    value={yAxis}
                    onChange={(e) => { ; setYAxis(e.target.value); }}
                >
                    {keys.map((k) => {
                        return (
                            <option key={k}>{k}</option>
                        )
                    })}
                </select>
                <button
                    className="btn btn-light border"
                    onClick={getGraphData}
                    disabled={isLoading}
                >
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    )}
                    Go
                </button>
            </div>

            {chartType === 'bar' && <BarChart data={props.chartData} x={xAxis} y={yAxis} />}
            {chartType === 'line' && <LineChart data={props.chartData} x={xAxis} y={yAxis} />}
            {chartType === 'pie' && <PieChart data={props.chartData} x={xAxis} y={yAxis} />}
            {chartType === 'doughnut' && <PieChart data={props.chartData} x={xAxis} y={yAxis} doughnut={1} />}
        </div>
    );
};
export default Chart;
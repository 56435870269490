// MessageInput.jsx
import React from 'react';

const MessageInput = ({ 
  value, 
  onChange, 
  onKeyDown, 
  onClick, 
  isTyping,
  quotedMessage, 
  setQuotedMessage,
  setCurrentParentId
}) => {
  const closeQuotedMessage = () => {
    setQuotedMessage(null); // Remove the quoted message
    setCurrentParentId(null);
  };

  return(
  <div className="input-group shadow-lg d-flex flex-row">
    <div className="d-flex flex-row flex-grow-1">
      {quotedMessage && (
        <div className="quoted-message d-flex flex-row align-items-center p-3 bg-light rounded-3 mb-0">
          <p className="mb-0 text-wrap">
            <span className="text-muted">Replying To: </span><br/><p className='quoted-message-paragraph'>{quotedMessage}</p>
          </p>
          <button className="btn btn-light ms-auto" onClick={closeQuotedMessage}>
            &times;
          </button>
        </div>
      )}
    </div>
    <div className="input-group shadow-lg d-flex flex-column flex-grow-1">
      <div className="d-flex flex-row align-items-end">
        <textarea
          type="text"
          className="form-control p-3 mb-0 chat-input"
          placeholder='Type your message'
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          style={{ minHeight: '58px' }}
          id="chatInput"
        />
        <button className="btn btn-light border p-3" onClick={onClick} disabled={isTyping}>
          <i className="bi bi-send"></i>
        </button>
        <button className="btn btn-light border p-3" disabled={isTyping}>
          <i className="bi bi-mic"></i>
        </button>
      </div>
    </div>
  </div>
  );
}

export default MessageInput;
